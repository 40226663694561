import React from 'react';
import PropTypes from 'prop-types';

// Used for divider lines throughout website
const PageDivider = (props) => (
  <div className={`${props.parentClass} divider${props.class ? ` ${props.class}` : ''}`}>
    <hr />
  </div>
);

PageDivider.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string,
};

PageDivider.defaultProps = {
  class: '',
};

export default PageDivider;
