import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Used for news filter items
const NewsTitleBarFilterItem = (props) => (
  <Link
    to={{
      pathname: '/news',
      search: props.title.toLowerCase() !== 'all' ? `?filter=${props.title.toLowerCase()}` : '',
    }}
    title={props.title === 'All' ? 'News filter off' : `Filter news by: ${props.title}`}
    className={`NewsTitleBar-filter-item${props.filter === props.title.toLowerCase() ? ' active' : ''}`}
  >
    {props.title}
  </Link>
);

NewsTitleBarFilterItem.propTypes = {
  filter: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewsTitleBarFilterItem;
