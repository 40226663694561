import React from 'react';

// Used for main nav toggle button
const NavigationMainToggle = (props) => (
  <div className="NavigationMain-toggle" onClick={() => props.toggleNav()}>
    <div className="bar" />
    <div className="bar" />
  </div>
);

export default NavigationMainToggle;
