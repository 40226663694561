import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import LazyLoad from 'react-lazyload';
import Reveal from 'react-reveal/Reveal';

import BackgroundGradient from './BackgroundGradient';

// Used only on audio website to show explosion graphic with bullet points
const PageExplosion = (props) => (
  <section
    className={`Component PageExplosion component-${props.id
    }${props.theme ? (` theme-${props.theme}`) : ''
    }${props.noOverflow ? ' no-overflow' : ''}`}
    style={props.zIndex ? { zIndex: props.zIndex } : null}
  >
    <Grid className={`Component-wrapper ${props.componentClass}`} fluid>
      {props.backgroundGradients && <BackgroundGradient gradients={props.backgroundGradients} />}

      <Row {...props.rows[0]}>
        <Col {...props.columns[0]}>
          <ul className="PageExplosion-bullet-points">
            {props.bulletPoints.map((el, index) => (
              <Reveal key={index} {...el.animation} disabled={!el.animation}>
                <li style={{ top: el.offsetY, left: el.offsetX }}>
                  {el.title}
                  <div className="PageExplosion-line" style={{ height: el.lineLength }} />
                </li>
              </Reveal>
            ))}
          </ul>
        </Col>
      </Row>
      <Row {...props.rows[1]}>
        <Col {...props.columns[0]}>
          <LazyLoad height="100%" offset={500} once>
            <Reveal {...props.image.animation} disabled={!props.image.animation}>
              <img src={props.image.src} alt="" className={`PageExplosion-image${props.image.class}`} />
            </Reveal>
          </LazyLoad>
        </Col>
      </Row>
    </Grid>
  </section>
);

PageExplosion.propTypes = {
  backgroundGradients: PropTypes.array,
  bulletPoints: PropTypes.array.isRequired,
  bulletPointsAnimation: PropTypes.object,
  columns: PropTypes.array.isRequired,
  componentClass: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  rows: PropTypes.array,
  theme: PropTypes.string,
  zIndex: PropTypes.number,
};

PageExplosion.defaultProps = {
  rows: [],
  theme: '',
  zIndex: 0,
};

export default PageExplosion;
