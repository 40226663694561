import React from 'react';
import PropTypes from 'prop-types';

// Used for bullet points with check mark throughout website
const PageTextBulletFact = (props) => (
  <div className={`${props.parentClass} bullet${props.class ? ` ${props.class}` : ''}`}>
    <span className="bullet-icon fact">
      <svg viewBox="0 0 33 33">
        <path
          d="M11.6018,22.7642l-4.86-4.847a.7954.7954,0,0,1,0-1.1415.84.84,0,0,1,1.169,0L12.1852,21.05,24.09,9.2366a.8389.8389,0,0,1,1.1679,0,.7936.7936,0,0,1,0,1.1415L12.77,22.7642a.841.841,0,0,1-1.1679,0ZM16,1A15,15,0,1,0,31,16,15.017,15.017,0,0,0,16,1m0-1A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
        />
      </svg>
    </span>
    <span className="bullet-text">
      {props.text}
    </span>
  </div>
);

PageTextBulletFact.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextBulletFact.defaultProps = {
  class: '',
};

export default PageTextBulletFact;
