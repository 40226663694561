import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

// Used for news pagination
const NewsPagination = (props) => (
  <Row around="xs" start="xs">
    <Col className="NewsPagination" lg={9} xs={12}>
      <div
        className="NewsPagination-wrapper"
        style={{ display: props.pageCount === 1 || props.currentPage > props.pageCount ? 'none' : 'flex' }}
      >
        <Link
          to={{
            search:
              (props.currentPage - 1 > 1 ? `?page=${props.currentPage - 1}` : '')
              + (props.filter !== 'all' ? `&filter=${props.filter.toLowerCase()}` : ''),
          }}
          className={`NewsPagination-button-prev${props.filter === props.title ? ' active' : ''}`}
          style={{ visibility: props.currentPage > 1 ? 'visible' : 'hidden' }}
        >
          <span>
            <img src="/assets/icons/icon_arrow_pagination.svg" alt="" />
          </span>
        </Link>
        <span className="NewsPagination-indicator">
          {props.currentPage}
          {' '}
          of
          {props.pageCount}
        </span>
        <Link
          to={{
            search:
              (props.currentPage + 1 <= props.pageCount ? `?page=${props.currentPage + 1}` : '')
              + (props.filter !== 'all' ? `&filter=${props.filter.toLowerCase()}` : ''),
          }}
          className={`NewsPagination-button-next${props.filter === props.title ? ' active' : ''}`}
          style={{ visibility: props.currentPage < props.pageCount ? 'visible' : 'hidden' }}
        >
          <span>
            <img src="/assets/icons/icon_arrow_pagination.svg" alt="" />
          </span>
        </Link>
      </div>
    </Col>
  </Row>
);

NewsPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
};

export default NewsPagination;
