import React from 'react';
import PropTypes from 'prop-types';

// Used only PEDs page for bubbles surrounding iphone
const PageTextBubble = (props) => (
  <div className={`${props.parentClass} bubble${props.class ? ` ${props.class}` : ''}`}>
    <h4 className="bubble-title">{props.title}</h4>
    <p className="bubble-copy">{props.copy}</p>
  </div>
);

PageTextBubble.propTypes = {
  class: PropTypes.string,
  copy: PropTypes.string.isRequired,
  parentClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

PageTextBubble.defaultProps = {
  class: '',
};

export default PageTextBubble;
