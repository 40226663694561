import React from 'react';
import PropTypes from 'prop-types';

import dateformat from 'dateformat';

// Used to format dates
const Date = (props) => (
  <span className="text-date">
    {dateformat(typeof props.date === 'number' ? props.date * 1000 : props.date, 'longDate')}
  </span>
);

Date.propTypes = {
  date: PropTypes.node.isRequired,
};

export default Date;
