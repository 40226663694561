import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

// Used for dividing news headlines
const NewsDivider = (props) => (
  <Row style={props.style} around="xs" start="xs">
    <Col {...props.settings} xs={12}>
      <hr />
    </Col>
  </Row>
);

NewsDivider.propTypes = {
  size: PropTypes.string,
};

export default NewsDivider;
