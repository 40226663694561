import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Used for info text blocks throughout website
class PageTextGaOptout extends Component {
  constructor(props) {
    super(props);
    this.gaOptout = this.gaOptout.bind(this);
  }

  gaOptout() {
    window['ga-disable-UA-155729289-2'] = true;
    document.cookie = 'rcl_statistics_consent=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = '_gid=; Path=/; domain=.nice-system.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = '_gat=; Path=/; domain=.nice-system.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = '_ga=; Path=/; domain=.nice-system.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  render() {
    return (
      <button
        type="button"
        href={this.props.link}
        className={this.props.parentClass + (this.props.class ? ` ${this.props.class}` : '')}
        target={this.props.target}
        onClick={this.gaOptout}
      >
        {this.props.text}
      </button>
    );
  }
}

PageTextGaOptout.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
  link: PropTypes.string,
};

PageTextGaOptout.defaultProps = {
  class: '',
};

export default PageTextGaOptout;
