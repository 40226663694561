import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import NavigationDivider from './NavigationDivider';
import NavigationMain from './NavigationMain';
import NavigationSub from './NavigationSub';

// Used to handle page navigation and its different states
class Navigation extends Component {
  constructor(props) {
    super(props);

    this.handlePathChange = this.handlePathChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleMainNav = this.toggleMainNav.bind(this);
    this.toggleSubNav = this.toggleSubNav.bind(this);
    this.updateViewport = this.updateViewport.bind(this);

    this.state = {
      activePath: '/',
      data: this.props.data,
      navStyle: 'Navigation',
      navMainStyle: 'NavigationMain',
      navSubIsOpen: false,
      navSubStyle: 'NavigationSub',
      subIsActive: false,
      viewportIsMobile: false,
    };
  }

  componentDidMount() {
    this.updateViewport();
    this.handlePathChange(this.props.location.pathname);

    // Add resize event listener for viewport size changes
    window.addEventListener('resize', this.updateViewport);

    // Add scroll event listener for viewport size changes
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handlePathChange(this.props.location.pathname);
    }
  }

  componentWillUnmount() {
    // Remove resize event listener for viewport size changes
    window.removeEventListener('resize', this.updateViewport);

    // Remove scroll event listener for viewport size changes
    window.removeEventListener('scroll', this.handleScroll);
  }

  handlePathChange(pathname) {
    const path = pathname.split('/')[1];
    let navTheme = 'bright';

    if (path === '' || pathname.split('/')[2] === 'case-studies') navTheme = 'dark';
    // Looks up theme for current path
    this.props.data.map((navItem) => {
      if (navItem.link === pathname) {
        navTheme = navItem.theme;
      }

      if (navItem.subNavItems) {
        navItem.subNavItems.map((subNavItem) => {
          if (subNavItem.link === pathname) {
            navTheme = subNavItem.theme;
          }

          return true;
        });
      }

      return true;
    });

    this.setState({
      activePath: path || '/',
      navStyle: `Navigation theme-${navTheme}`,
      navMainStyle: 'NavigationMain',
      navSubStyle: 'NavigationSub',
      navToggleStyle: 'NavigationMain-toggle',
      subIsActive: ['customer-support', 'insights', 'system'].includes(path),
    });

    // Change position back to static after transition
    // -> CSS-Transition needs to set position of new page to absolute in order to work,
    // cf. /css/_animations.scss .fade-enter & .fade-exit
    document.body.style.position = 'static';
  }

  // Handle scroll behavior depending on navigation state (sub nav active etc.)
  handleScroll() {
    const supportPageOffset = window.pageYOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    const scroll = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

    if (this.state.subIsActive) {
      if (this.state.viewportIsMobile) {
        this.setState((prevState) => ({
          navSubStyle:
            scroll > 47
              ? (!prevState.navSubStyle.includes('sticky') ? `${prevState.navSubStyle} sticky` : prevState.navSubStyle)
              : prevState.navSubStyle.replace(/ sticky/g, ''),
        }));
      } else {
        this.setState((prevState) => ({
          navSubStyle:
          scroll > 109
            ? (!prevState.navSubStyle.includes('sticky') ? `${prevState.navSubStyle} sticky` : prevState.navSubStyle)
            : prevState.navSubStyle.replace(/ sticky/g, ''),
        }));
      }
    } else if (this.state.viewportIsMobile) {
      this.setState((prevState) => ({
        navMainStyle:
          scroll > 0
            ? (!prevState.navMainStyle.includes('sticky') ? `${prevState.navMainStyle} sticky` : prevState.navMainStyle)
            : prevState.navMainStyle.replace(/ sticky/g, ''),
      }));
    } else {
      this.setState((prevState) => ({
        navMainStyle:
          scroll > 47
            ? (!prevState.navMainStyle.includes('sticky') ? `${prevState.navMainStyle} sticky` : prevState.navMainStyle)
            : prevState.navMainStyle.replace(/ sticky/g, ''),
      }));
    }
  }

  // Handle main nav toggle
  toggleMainNav() {
    this.setState((prevState) => ({
      navMainStyle: !prevState.navMainStyle.includes('open') ? `${prevState.navMainStyle} open` : prevState.navMainStyle.replace(/ open/g, ''),
      navSubStyle: prevState.navSubStyle.includes('open') ? prevState.navSubStyle.replace(/ open/g, '') : prevState.navSubStyle,
    }));

    !this.state.navMainStyle.includes('open')
      ? document.body.style.position = 'fixed'
      : document.body.style.position = 'static';
  }

  // Handle sub nav toggle
  toggleSubNav() {
    this.setState((prevState) => ({
      navSubStyle: !prevState.navSubStyle.includes('open') ? `${prevState.navSubStyle} open` : prevState.navSubStyle.replace(/ open/g, ''),
    }));
  }

  // Check if viewport width is smaller than 768px
  // Used to switch to mobile nav
  updateViewport() {
    this.setState({ viewportIsMobile: window.innerWidth < 992 });
  }

  render() {
    return (
      <nav className={this.state.navStyle}>
        <NavigationMain {...this.state} toggleNav={this.toggleMainNav} />
        <NavigationDivider {...this.state} />
        <NavigationSub {...this.state} toggleNav={this.toggleSubNav} />
      </nav>
    );
  }
}

export default withRouter(Navigation);
