import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import YouTube from 'react-youtube';

// Used to teaser a youtube video and open said video in an overlay (modal) when clicked
class PageVideoTeaser extends Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      modalOpen: false,
    };
  }

  // Modal component displays overlay when modalOpen-state is true
  openModal() {
    this.setState({
      modalOpen: true,
    });
  }

  // Modal component hides overlay when modalOpen-state is false
  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    // Overlay settings
    const modalSettings = {
      open: this.state.modalOpen,
      onClose: this.closeModal,
      classNames: {
        overlay: 'Modal-overlay',
        modal: 'Modal-content',
        closeButton: 'Modal-button-close',
      },
    };

    // YouTube player settings
    const youTubeSettings = {
      className: 'Modal-player',
      containerClassName: 'Modal-video-container',
    };

    return (
      <div className={`${this.props.parentClass} video-teaser${this.props.class ? ` ${this.props.class}` : ''}`}>
        {this.props.image && <img src={this.props.image} alt="" className="video-teaser-image" />}

        <img
          src="/assets/icons/icon_play_button.svg"
          alt=""
          className="video-teaser-button"
          onClick={this.openModal}
          style={{ position: !this.props.image && 'relative' }}
        />

        <Modal {...modalSettings}>
          <YouTube videoId={this.props.youTubeId} {...youTubeSettings} />
        </Modal>
      </div>
    );
  }
}

PageVideoTeaser.propTypes = {
  class: PropTypes.string,
  image: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  youTubeId: PropTypes.string.isRequired,
};

PageVideoTeaser.defaultProps = {
  class: '',
};

export default PageVideoTeaser;
