import React from 'react';
import PropTypes from 'prop-types';

const PageSliderArrow = (props) => (
  <svg viewBox="0 0 20 20" className={`PageSlider-arrow ${props.direction}`} onClick={props.onClick}>
    <path
      d="M10.7237,10,8.5825,7.5528a.3333.3333,0,0,1,.5017-.439l2.3333,2.6667a.3333.3333,0,0,1,0,.439L9.0842,12.8862a.3333.3333,0,0,1-.5017-.439Z"
    />
  </svg>
);

PageSliderArrow.propTypes = {
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PageSliderArrow;
