import React from 'react';
import find from 'lodash.find';
import { CSSTransition, transit } from 'react-css-transition';
import { NavLink, withRouter } from 'react-router-dom';

import NavigationItem from './NavigationItem';
import NavigationSubToggle from './NavigationSubToggle';

CSSTransition.childContextTypes = {};

// Used for sub nav of page navigation
const NavigationSub = (props) => {
  const activeMainNavItem = find(props.data, (item) => (
    props.activePath === item.link.split('/').pop()
  ));

  const style = () => {
    if (!props.navSubStyle.includes('open')) return {};

    const closedNavHeight = document.getElementById('NavigationSub')?.clientHeight || 54;

    return {
      height: `${activeMainNavItem.subNavItems.length * 48 + closedNavHeight}px`,
    };
  };

  return (
    <nav
      id="NavigationSub"
      className={props.navSubStyle}
      style={style()}
    >
      {props.data.map((item) => {
        const isVisible = props.activePath === item.link.split('/').pop();
        const hasItems = item?.subNavItems?.length;

        if (hasItems && !props.viewportIsMobile) {
          return (
            <CSSTransition
              key={item.id}
              className="NavigationSub-wrapper-outer"
              defaultStyle={{ opacity: 0, transform: 'translateY(-30px)', visibility: 'hidden' }}
              enterStyle={{ opacity: transit(1, 500, 'ease-in-out'), transform: transit('translateY(0)', 500, 'ease-in-out') }}
              leaveStyle={{ opacity: transit(0, 500, 'ease-in-out'), transform: transit('translateY(30px)', 500, 'ease-in-out') }}
              activeStyle={{ opacity: 1, transform: 'translateY(0)', visibility: 'visible' }}
              active={isVisible}
            >
              <div className="NavigationSub-wrapper">
                <div className="NavigationSub-indicator">
                  <NavLink exact to={item.link} activeClassName="active">
                    {item.title}
                  </NavLink>
                </div>
                <ul>
                  {item.subNavItems.map((itemSub) => (
                    <NavigationItem
                      key={itemSub.id}
                      class="NavigationSub-item"
                      {...itemSub}
                    />
                  ))}
                </ul>
              </div>
            </CSSTransition>
          );
        }

        if (hasItems && isVisible) {
          return (
            <div key={item.id} className="NavigationSub-wrapper">
              <div className="NavigationSub-indicator">
                <NavLink exact to={item.link} activeClassName="active">
                  {item.title}
                </NavLink>
                <NavigationSubToggle toggleNav={props.toggleNav} />
              </div>
              <div className="NavigationSub-items">
                {item.subNavItems.map((itemSub) => (
                  <NavigationItem
                    key={itemSub.id}
                    class="NavigationSub-item"
                    {...itemSub}
                  />
                ))}
              </div>
            </div>
          );
        }

        return null;
      })}
    </nav>
  );
};

export default withRouter(NavigationSub);
