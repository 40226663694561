import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
// Used for copy text blocks throughout website
class PageTextCopy extends Component {
  constructor(props) {
    super(props);

    this.getHighlightedText = this.getHighlightedText.bind(this);
  }

  // Highlight words with \b-tag in front of them
  getHighlightedText(text) {
    // Split string at \b-tag
    const parts = text.split('\b');

    // If string does not contain \b-tag return text as it is
    if (parts.length === 1) {
      return text;
    }

    return (
      <>
        {parts.map((part, index) => {
          if (index > 0) {
            // Put <strong>-tag around first word of string after \b-tag
            return (
              <React.Fragment key={index}>
                <strong>{part.substr(0, part.indexOf(' '))}</strong>
                {' '}
                {part.substr(part.indexOf(' ') + 1)}
              </React.Fragment>
            );
          }
          return (
          // Return text before first \b-tag as it is
            <React.Fragment key={index}>{part}</React.Fragment>
          );
        })}
      </>
    );
  }

  render() {
    return (
      <Linkify properties={{ target: '_blank' }}>
        <p className={`${this.props.parentClass} copy${this.props.class ? ` ${this.props.class}` : ''}`}>
          {this.getHighlightedText(this.props.text)}
        </p>
      </Linkify>
    );
  }
}

PageTextCopy.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextCopy.defaultProps = {
  class: '',
};

export default PageTextCopy;
