import React from 'react';
import { NavLink } from 'react-router-dom';

// Used for navigation link items
const NavigationItem = (props) => (
  <NavLink
    to={props.link}
    className={props.class}
    activeClassName="active"
  >
    {props.title}
  </NavLink>
);

export default NavigationItem;
