import React from 'react';
import PropTypes from 'prop-types';

import PageTextCopy from './PageTextCopy';
import PageTextSubTitle from './PageTextSubTitle';

const PageSliderText = (props) => (
  <div className="PageSlider text gutter">
    <h5 className="PageSlider mobile-title">{props.title}</h5>

    {props.subTitle && <PageTextSubTitle parentClass="PageSlider" text={props.subTitle} />}
    {props.copy && <PageTextCopy parentClass="PageSlider" text={props.copy} />}
  </div>
);

PageSliderText.propTypes = {
  copy: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

export default PageSliderText;
