import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import LazyLoad from 'react-lazyload';
import Reveal from 'react-reveal/Reveal';

import BackgroundGradient from './BackgroundGradient';
import PageColumns from './PageColumns';

// Used only on user interface page to show lighting UI
const PageLightUI = (props) => (
  <section className={`Component PageLightUI theme-${props.theme}`} style={{ zIndex: props.zIndex }}>
    <Grid className={`Component-wrapper ${props.componentClass}`} fluid>
      {props.backgroundGradients && <BackgroundGradient gradients={props.backgroundGradients} />}

      <PageColumns {...props} />

      <Row {...props.row}>
        <Col lg={6} md={7} xs={11}>
          <LazyLoad height="100%" offset={500} once>
            <Reveal {...props.image.animation} disabled={!props.image.animation}>
              <div className="PageLightUI-image">
                <img src={props.image.src} alt="" />
              </div>
            </Reveal>
          </LazyLoad>
        </Col>
      </Row>
    </Grid>

    <div className="PageLightUI-background" />
  </section>
);

PageLightUI.propTypes = {
  columns: PropTypes.array.isRequired,
  componentClass: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  row: PropTypes.object,
  theme: PropTypes.string,
  zIndex: PropTypes.number,
};

export default PageLightUI;
