import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

// Used for loading animations
const LoadingSpinner = (props) => (
  <div className={`LoadingSpinner${props.fullHeight ? ' full-height' : ''}`}>
    <ClipLoader sizeUnit="px" size={60} color="#c5c5c5" />
  </div>
);

LoadingSpinner.propTypes = {
  fullHeight: PropTypes.bool,
};

export default LoadingSpinner;
