import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import FooterItem from './FooterItem';

// Used for navigation in page footer
class Footer extends Component {
  constructor(props) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
  }

  // Render footer items according to data from FooterItems.json
  renderContent(elements) {
    let contents = [];

    contents = elements.map((element, elementIndex) => {
      let columns = [];

      if (element.id > 0) {
        columns = element.columns.map((column, columnIndex) => {
          let innerColumns = [];

          if (column.columns) {
            innerColumns = column.columns.map((innerColumn, innerColumnIndex) => (
              <Col key={innerColumnIndex} xs={12} className="Footer-nav-col padbot-xs">
                <h3>{innerColumn.title}</h3>

                {innerColumn.items.map(
                  (item, itemIndex) => <FooterItem key={itemIndex} {...item} />,
                )}
              </Col>
            ));
          } else {
            innerColumns = column.items.map((item, index) => <FooterItem key={index} {...item} />);
          }

          return <Col key={columnIndex} {...column.settings}>{innerColumns}</Col>;
        });
      }

      return (
        <Col key={elementIndex} {...element.settings}>
          <Row className="Footer-nav-row">{columns}</Row>
        </Col>
      );
    });

    return <Row className="Footer-nav-row">{contents}</Row>;
  }

  render() {
    return (
      <footer className="Footer">
        <div className={this.props.data[0].componentClass}>
          <Grid fluid>{this.renderContent(this.props.data)}</Grid>
        </div>
      </footer>
    );
  }
}

export default Footer;
