import React from 'react';
import PropTypes from 'prop-types';
import Reveal from 'react-reveal/Reveal';

// Used for blue background gradients throughout website
const BackgroundGradient = (props) => (
  <div className="BackgroundGradient">
    {props.gradients.map((gradient, index) => (
      <Reveal key={index} {...gradient.animation} disabled={!gradient.animation}>
        <div
          className="BackgroundGradient-item"
          style={{
            top: gradient.offsetY || 0,
            left: gradient.offsetX || 0,
            height: gradient.height,
            width: gradient.width,
            backgroundImage: gradient.gradient,
          }}
        />
      </Reveal>
    ))}
  </div>
);

BackgroundGradient.propTypes = {
  gradients: PropTypes.array.isRequired,
};

export default BackgroundGradient;
