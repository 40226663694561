import React from 'react';
import PropTypes from 'prop-types';

// Used for "learn more" teasers throughout website
const PageLearnMore = (props) => (
  <div
    className={`${props.parentClass} learn-more info-text${props.class ? ` ${props.class}` : ''}`}
    onClick={(e) => props.handleScrollTo(e)}
  >
    <span>
      <svg viewBox="0 0 20 20" className="icon">
        <path
          d="M16.381,9,10.7281,2.1357A1,1,0,0,1,12.2719.8643l7,8.5a1,1,0,0,1,0,1.2714l-7,8.5a1,1,0,1,1-1.5438-1.2714L16.381,11H1.5a1,1,0,0,1,0-2Z"
        />
      </svg>
    </span>
    <span className="text">
      {props.text}
    </span>
  </div>
);

PageLearnMore.propTypes = {
  class: PropTypes.string,
  handleScrollTo: PropTypes.func.isRequired,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string,
};

PageLearnMore.defaultProps = {
  class: '',
  text: 'Learn more',
};

export default PageLearnMore;
