import React from 'react';

const IconArrow = () => (
  <svg viewBox="0 0 20 20" className="icon-arrow">
    <path
      d="M16.381,9,10.7281,2.1357A1,1,0,0,1,12.2719.8643l7,8.5a1,1,0,0,1,0,1.2714l-7,8.5a1,1,0,1,1-1.5438-1.2714L16.381,11H1.5a1,1,0,0,1,0-2Z"
    />
  </svg>
);

export default IconArrow;
