import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-flexbox-grid';

import BackgroundGradient from './BackgroundGradient';
import PageColumns from './PageColumns';

// Used for contact section throughout website
const PageContact = (props) => (
  <section
    className={`Component PageContact component-${props.id
    }${props.theme ? (` theme-${props.theme}`) : ''
    }${props.noOverflow ? ' no-overflow' : ''}`}
    style={props.zIndex ? { zIndex: props.zIndex } : null}
  >
    <Grid className={`Component-wrapper content ${props.componentClass}`} fluid>
      {
        props.theme !== 'bright'
        && props.theme !== 'grey'
        && props.theme !== 'grey-light'
        && props.theme !== 'white'
        && props.contact[0].backgroundGradients
        && <BackgroundGradient gradients={props.contact[0].backgroundGradients} />
      }

      <PageColumns {...props.contact[0]} />
    </Grid>
  </section>
);

PageContact.propTypes = {
  componentClass: PropTypes.string,
  theme: PropTypes.string,
  zIndex: PropTypes.number,
};

PageContact.defaultProps = {
  theme: '',
  zIndex: 0,
};

export default PageContact;
