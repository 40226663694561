import React from 'react';
import PropTypes from 'prop-types';

// Used for copy subtitle headlines throughout website
const PageTextSubTitle = (props) => (
  <h3 className={`${props.parentClass} subTitle${props.class ? ` ${props.class}` : ''}`}>
    {props.text}
  </h3>
);

PageTextSubTitle.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextSubTitle.defaultProps = {
  class: '',
};

export default PageTextSubTitle;
