import React from 'react';
import PropTypes from 'prop-types';

const stripPath = (path) => {
  const strippedPath = path.split('/')[5].substring(13);

  return strippedPath;
};

// Used for file attachments (e.g. pdfs) in news articles
const NewsFileAttachment = (props) => (
  <a className="News-file-attachment" href={`https://www.nice-system.com/cockpit/${props.path}`}>
    <img src="/assets/icons/icon_pdf.svg" alt={props.alt} />
    <span>{stripPath(props.path)}</span>
  </a>
);

NewsFileAttachment.propTypes = {
  path: PropTypes.string.isRequired,
};

export default NewsFileAttachment;
