import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

import NewsMeta from './NewsMeta';
import PageLink from './PageLink';

// Used for news preview (e.g. title, meta, link, featured image)
const NewsPreviewWithImage = (props) => (
  <Reveal effect="fade-in-up" duration={1000}>
    <Row className="NewsPreviewWithImage" between="xs" start="xs">
      <Col className="NewsPreviewWithImage-image" md={6} xs={12}>
        <Link to={`/news/${props.slug}`} title={`Read: ${props.title}`}>
          <img
            src={`https://www.nice-system.com/cockpit${props.featuredImage.path}`}
            alt={props.featuredImage.meta ? props.featuredImage.meta.title : ''}
          />
        </Link>
      </Col>
      <Col className="NewsPreviewWithImage-copy" md={5} xs={12}>
        <NewsMeta categories={props.categories} date={props.customDate !== '' ? props.customDate : props._created} />
        <Link to={`/news/${props.slug}`} title={`Read: ${props.title}`} className="no-styling">
          <h2>{props.title}</h2>
        </Link>
        <PageLink {...{
          icon: 'arrow', text: 'Read', title: `Read: ${props.title}`, url: `/news/${props.slug}`,
        }}
        />
      </Col>
    </Row>
  </Reveal>
);

NewsPreviewWithImage.propTypes = {
  _created: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  customDate: PropTypes.string,
  featuredImage: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewsPreviewWithImage;
