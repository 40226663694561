import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Date from './Date';

// Used to show meta data (e.g. data etc.) in news articles
const NewsMeta = (props) => (
  <div className="NewsMeta">
    {props.categories.map((category, index) => (
      <Link
        key={index}
        to={{
          pathname: '/news',
          search: `?filter=${category.toLowerCase()}`,
        }}
        title={`Filter news by: ${category}`}
        className="NewsMeta-category"
      >
        {category}
      </Link>
    ))}
    {props.date && <Date date={props.date} />}
  </div>
);

NewsMeta.propTypes = {
  categories: PropTypes.array.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default NewsMeta;
