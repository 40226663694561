import React from 'react';
import PropTypes from 'prop-types';

const PageSliderNavItem = (props) => (
  <li className={props.isActive ? 'active' : undefined} onClick={() => props.slideTo(props.index)}>
    <h5>{props.title}</h5>
  </li>
);

PageSliderNavItem.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  slideTo: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default PageSliderNavItem;
