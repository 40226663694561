import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';

import NewsTitleBarFilterItem from './NewsTitleBarFilterItem';

// Used for news overview headline and filter bar
const NewsTitleBar = (props) => {
  const renderFilterCategories = () => {
    // Get categories string from CMS scheme and divide it at commas to get an array with categories
    const categories = props.scheme.fields[6].options.options.split(', ');

    const filterItems = [];
    const itemProps = {
      filter: props.filter,
      setFilter: props.setFilter,
    };

    // Add filter item "All" to filterItems array
    filterItems.push(
      <NewsTitleBarFilterItem key="first" title="All" {...itemProps} />,
    );

    // Add categories from categories array to filterItems array
    categories.map((item, index) => {
      filterItems.push(
        <NewsTitleBarFilterItem key={index} title={item} {...itemProps} />,
      );

      return true;
    });

    return filterItems;
  };

  return (
    <nav className="Component NewsTitleBar">
      <Grid className="Component-wrapper width-md" fluid>
        <Row around="xs" start="xs">
          <Col lg={6} xs={12}>
            <h1 className="headline-thin">Our latest news</h1>
          </Col>
          <Col className="NewsTitleBar-filter" lg={6} xs={12}>
            <span className="NewsTitleBar-filter-info">Filter by category:</span>
            {renderFilterCategories()}
          </Col>
        </Row>
      </Grid>
    </nav>
  );
};

NewsTitleBar.propTypes = {
  filter: PropTypes.string.isRequired,
  scheme: PropTypes.object.isRequired,
};

export default NewsTitleBar;
