import { Component } from 'react';
import { withRouter } from 'react-router';

// Used to scroll to top of page when navigating to new page
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    // If opened page is "new" scroll to top after defined delay
    if (this.props.location !== prevProps.location) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, this.props.delay);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
