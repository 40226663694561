import React from 'react';
import { withRouter } from 'react-router-dom';

import NavigationItem from './NavigationItem';
import NavigationMainLogo from './NavigationMainLogo';
import NavigationMainToggle from './NavigationMainToggle';

// Used for main nav of page navigation
const NavigationMain = (props) => (
  <nav className={props.navMainStyle}>
    <div className="NavigationMain-wrapper">
      <div className="NavigationMain-icons">
        <NavigationMainLogo />
        <NavigationMainToggle toggleNav={props.toggleNav} />
      </div>
      <div className="NavigationMain-items">
        {props.data.map((item) => {
          if (item.active) {
            return <NavigationItem key={item.id} class="NavigationMain-item" {...item} />;
          }
          return null;
        })}

        {/*
          Display legal button if viewport is mobile
        */}
        {props.viewportIsMobile
          && (
          <a
            href="https://www.lufthansa-technik.com/en/imprint"
            target="_blank"
            rel="noopener noreferrer"
            className="NavigationMain-legal info-text"
          >
            Legal
          </a>
          )}
      </div>
    </div>
  </nav>
);

export default withRouter(NavigationMain);
