import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { withRouter } from 'react-router-dom';

import Footer from '../components/Footer';
import LoadingSpinner from '../components/LoadingSpinner';
import NewsDivider from '../components/NewsDivider';
import NewsPagination from '../components/NewsPagination';
import NewsPreview from '../components/NewsPreview';
import NewsPreviewWithImage from '../components/NewsPreviewWithImage';
import NewsTitleBar from '../components/NewsTitleBar';

class News extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.getCurrentFilter = this.getCurrentFilter.bind(this);
    this.getCurrentPage = this.getCurrentPage.bind(this);
    this.renderNewsPreviews = this.renderNewsPreviews.bind(this);
    this.setPageCount = this.setPageCount.bind(this);

    this.state = {
      filter: this.getCurrentFilter(),
      currentPage: this.getCurrentPage(),
      isLoading: true,
      news: null,
      pageCount: 1,
      perPageLimit: 8,
    };
  }

  componentDidMount() {
    // Fetch data for current page
    this.fetchData((this.state.currentPage - 1) * this.state.perPageLimit);
  }

  componentDidUpdate(prevProps, prevState) {
    // Fetch new data and scroll to top if page was changed
    if (
      prevState.currentPage !== this.state.currentPage
      || prevState.filter !== this.state.filter
    ) {
      this.fetchData((this.state.currentPage - 1) * this.state.perPageLimit);
      window.scrollTo(0, 0);
    }

    // Update page count if news changed (e.g. when filter setting was changed)
    if (prevState.news !== this.state.news) {
      this.setPageCount();
    }
  }

  getCurrentFilter() {
    let filter = null;

    // Get url parameters
    const params = new URLSearchParams(window.location.search);

    // Get current filter from url paramters
    switch (params.get('filter')) {
      case 'news':
        filter = 'news';
        break;

      case 'press':
        filter = 'press';
        break;

      case 'video':
        filter = 'video';
        break;

      default:
        filter = 'all';
    }

    return filter;
  }

  getCurrentPage() {
    let currentPage = 1;

    // Get url parameters
    const params = new URLSearchParams(window.location.search);

    // Get current page from url parameters
    if (params.get('page') !== null) {
      currentPage = parseInt(params.get('page'));
    }

    return currentPage;
  }

  // Calculate page count
  setPageCount() {
    let pageCount = 1;

    if (this.state.news) {
      pageCount = Math.ceil(this.state.news.total / this.state.perPageLimit);
    }

    this.setState({ pageCount });
  }

  // Fetch complete news data for current page to display
  fetchData(startAt) {
    const apiToken = '5f6e018c8550e45f4352d130d8be50';
    const apiUrl = 'https://www.nice-system.com/cockpit/api';

    const filter = this.state.filter[0].toUpperCase() + this.state.filter.substr(1);

    // Fetch news data without content and fileAttachment
    fetch(`${apiUrl}/collections/get/news?token=${apiToken}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fields: {
          content: 0,
          fileAttachment: 0,
        },
        filter: {
          categories: this.state.filter !== 'all' ? filter : undefined,
          published: true,
        },
        limit: this.state.perPageLimit,
        skip: startAt !== 0 ? startAt : 0,
        sort: {
          customDate: -1,
        },
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then(
        (response) => this.setState({ isLoading: false, news: response }),
      ); // Add news articles to state
  }

  // Render news previews
  renderNewsPreviews() {
    const newsPreviewItems = [];

    if (this.state.news !== null && this.state.news.total > 0) {
      this.state.news.entries.map((item, index, news) => {
        if (item.featuredImage) {
          newsPreviewItems.push(
            <React.Fragment key={index}>
              {!!newsPreviewItems.length
                && !news[index - 1].featuredImage
                && <NewsDivider settings={{ lg: 12 }} />}
              <NewsPreviewWithImage {...item} />
              <NewsDivider settings={{ lg: 12 }} />
            </React.Fragment>,
          );
        } else {
          newsPreviewItems.push(
            <NewsPreview key={index} {...item} />,
          );

          if ((news[index + 1] && !news[index + 1].featuredImage) || !news[index + 1]) {
            newsPreviewItems.push(
              <NewsDivider settings={{ lg: 9 }} key={`divider${index}`} />,
            );
          }
        }

        return true;
      });
    } else {
      newsPreviewItems.push(
        <Reveal key="1" effect="fade-in-up" duration={1000}>
          <Row className="News-preview" around="xs" start="xs">
            <Col lg={9} xs={12}>
              <h3>There are no news in this category.</h3>
            </Col>
          </Row>
        </Reveal>,
      );
    }

    return newsPreviewItems;
  }

  render() {
    return (
      <DocumentMeta title="News" canonical="https://www.nice-system.com/insights/news">
        <div id="main" className="Page News theme-bright">
          <NewsTitleBar filter={this.state.filter} scheme={this.props.scheme} />
          <section className="Component">
            <Grid className="Component-wrapper width-sm" fluid>
              {this.state.isLoading ? <LoadingSpinner /> : this.renderNewsPreviews()}
              {!this.state.isLoading && <NewsPagination {...this.state} />}
            </Grid>
          </section>
        </div>
        <Footer data={this.props.layout.footer} />
      </DocumentMeta>
    );
  }
}

export default withRouter(News);
