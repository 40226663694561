import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { withRouter } from 'react-router-dom';

import Footer from '../components/Footer';
import LoadingSpinner from '../components/LoadingSpinner';
import NewsDivider from '../components/NewsDivider';
import NewsFileAttachment from '../components/NewsFileAttachment';
import NewsTeaser from '../components/NewsTeaser';
import NewsMeta from '../components/NewsMeta';

class NewsArticle extends Component {
  constructor(props) {
    super(props);

    this.createMarkup = this.createMarkup.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.goBack = this.goBack.bind(this);

    this.state = {
      article: null,
      isLoading: true,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  // Replace markups from CMS with needed markups
  createMarkup(html) {
    html = html.replace(
      '<p><iframe',
      '<p class="video"><iframe',
    );

    html = html.replace(
      '<p><img',
      '<p class="image"><img',
    );

    html = html.replace(
      '<p><video',
      '<p class="video"><img',
    );

    html = html.replace(
      'allowfullscreen="allowfullscreen"',
      'allowfullscreen="allowfullscreen" controls=0 modestbranding rel=0',
    );

    return { __html: html };
  }

  // Fetch data for selected article from CMS based on slug from url
  fetchData() {
    const apiToken = '5f6e018c8550e45f4352d130d8be50';
    const apiUrl = 'https://www.nice-system.com/cockpit/api';

    fetch(`${apiUrl}/collections/get/news?token=${apiToken}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filter: {
          slug: this.props.match.params.slug,
          published: true,
        },
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((response) => this.setState({ isLoading: false, article: response.entries[0] }));
  }

  // Handle go back button
  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { article } = this.state;

    return (
      <DocumentMeta
        title={this.state.article !== null ? article.title : ''}
        canonical={`https://www.nice-system.com/insights/news/${this.state.article !== null ? article.slug : ''}`}
      >
        <div id="main" className="Page NewsArticle theme-bright">
          <article className="Component">
            <Grid className="Component-wrapper width-lg" fluid>
              {this.state.isLoading && <LoadingSpinner />}
              {!this.state.isLoading
                && (
                <>
                  <Reveal key="1" effect="fade-in-up" duration={1000}>
                    <Row around="xs" start="xs">
                      <Col lg={9} xs={12}>
                        <section className="NewsArticle-meta">
                          <NewsMeta
                            categories={article.categories}
                            date={article.customDate || article._created}
                          />
                          <h1>{article.title}</h1>
                        </section>
                        <section
                          className="NewsArticle-content"
                          dangerouslySetInnerHTML={this.createMarkup(article.content)}
                        />
                        <section className="NewsArticle-footer">
                          <div className="NewsArticle-footer-wrapper">
                            {this.state.article.fileAttachment
                              && <NewsFileAttachment path={this.state.article.fileAttachment} />}
                            <span className="button-primary button-md" onClick={this.goBack}>
                              <span>← Go back</span>
                            </span>
                          </div>
                        </section>
                      </Col>
                    </Row>
                  </Reveal>
                  <NewsDivider settings={{ lg: 10 }} />
                </>
                )}
            </Grid>
          </article>
          {!this.state.isLoading
            && (
            <NewsTeaser
              componentClass="padtop-lg padbot-md"
              currentSlug={this.props.match.params.slug}
              latestArticles={this.props.layout.latestArticles.entries}
            />
            )}
        </div>
        <Footer data={this.props.layout.footer} />
      </DocumentMeta>
    );
  }
}

export default withRouter(NewsArticle);
