import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

import NewsTeaserItem from './NewsTeaserItem';

// Used for news teasers throughout website
const NewsTeaser = (props) => {
  // Render news teaser items, latest articles are fetched in App.js
  const renderItems = () => {
    let index = 0;
    const test = [];

    props.latestArticles.map((item) => {
      if (item.slug !== props.currentSlug && index < 3) {
        test.push(
          <NewsTeaserItem
            key={index}
            delay={index * 100}
            settings={{ lg: 4, md: 6, xs: 12 }}
            {...item}
          />,
        );

        index++;
      }

      return true;
    });

    return test;
  };

  return (
    <section
      className={`Component NewsTeaser component-${props.id || 0}${props.theme ? ` theme-${props.theme}` : ''}`}
    >
      <Grid className={`Component-wrapper width-md${props.componentClass ? ` ${props.componentClass}` : ''}`} fluid>
        <Row>
          <Col xs={12}>
            <h2 className="NewsTeaser-headline">{props.title}</h2>
          </Col>
        </Row>
        <Row>
          {renderItems()}
        </Row>
        <Row center="xs" className="padtop-sm">
          <Link className="button-primary button-lg" to="/news" title="Go to news page">
            <span>Show news archive</span>
          </Link>
        </Row>
      </Grid>
    </section>
  );
};

NewsTeaser.propTypes = {
  className: PropTypes.string,
  latestArticles: PropTypes.array.isRequired,
  title: PropTypes.string,
};

NewsTeaser.defaultProps = {
  title: 'Latest articles',
};

export default NewsTeaser;
