import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

import NewsMeta from './NewsMeta';
import PageLink from './PageLink';

// Used for news teaser items
const NewsTeaserItem = (props) => (
  <Col {...props.settings} className="NewsTeaser-item">
    <Reveal effect="fade-in-up" duration={1000} delay={props.delay}>
      <div className="NewsTeaser-item-wrapper">
        <NewsMeta categories={props.categories} />
        <Link to={`/news/${props.slug}`} title={`Read: ${props.title}`} className="no-styling">
          <h3>{props.title}</h3>
        </Link>
        <PageLink {...{
          icon: 'arrow', text: 'Read', title: `Read: ${props.title}`, url: `/news/${props.slug}`,
        }}
        />
      </div>
    </Reveal>
  </Col>
);

NewsTeaserItem.propTypes = {
  _created: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewsTeaserItem;
