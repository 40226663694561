import React from 'react';
import PropTypes from 'prop-types';

import PageSliderNavItem from './PageSliderNavItem';

const PageSliderNav = (props) => (
  <nav className="PageSlider-nav">
    <ul>
      {props.slides.map((slide, index) => (
        <PageSliderNavItem
          key={index}
          title={slide.title}
          index={index}
          isActive={props.currentSlide === index}
          slideTo={() => props.slideTo(index)}
        />
      ))}
    </ul>
  </nav>
);

PageSliderNav.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  slides: PropTypes.array.isRequired,
  slideTo: PropTypes.func.isRequired,
};

export default PageSliderNav;
