import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

// Used for AR quicklook (currently not implemented)
const QuickLook = (props) => (
  <LazyLoad height="100%" offset={500} once>
    <div className={`${props.parentClass} quick-look${props.class ? ` ${props.class}` : ''}`}>
      <a href={props.link} rel="ar" title={props.linkTitle}>
        <img src={props.src} alt={props.alt} />
      </a>
    </div>
  </LazyLoad>
);

QuickLook.propTypes = {
  alt: PropTypes.string,
  class: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkTitle: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

QuickLook.defaultProps = {
  alt: '',
  class: '',
  linkTitle: '',
};

export default QuickLook;
