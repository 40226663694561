import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

// Used for images throughout website
const PageImage = (props) => (
  <LazyLoad height="100%" offset={500} once>
    <div className={`${props.parentClass} image${props.class ? ` ${props.class}` : ''}`}>
      <img src={props.src} alt={props.alt} />
    </div>
  </LazyLoad>
);

PageImage.propTypes = {
  alt: PropTypes.string,
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

PageImage.defaultProps = {
  alt: '',
  class: '',
};

export default PageImage;
