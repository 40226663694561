import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

import Date from './Date';
import PageLink from './PageLink';

// Used for news preview (e.g. title, meta, link, featured image)
const CaseStudyPreview = (props) => (
  <Reveal effect="fade-in-up" duration={1000}>
    <Row className="CaseStudyPreview padtop-sm padbot-sm" between="xs" start="xs">
      <Col className="CaseStudyPreview-image" md={6} xs={12}>
        <Link to={props.link.url} title={props.link.title}>
          <img src={props.image.src} alt={props.image.alt} />
        </Link>
      </Col>
      <Col className="CaseStudyPreview-copy" md={5} xs={12}>
        <Date date={props.date} />
        <h3>{props.subTitle}</h3>
        <Link to={props.link.url} title={props.link.title} className="no-styling">
          <h2 className="headline-thin">{props.title}</h2>
        </Link>
        <PageLink {...props.link} />
      </Col>
    </Row>
  </Reveal>
);

CaseStudyPreview.propTypes = {
  date: PropTypes.string.isRequired,
  image: PropTypes.object,
  link: PropTypes.object,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CaseStudyPreview;
