import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

import NewsMeta from './NewsMeta';
import PageLink from './PageLink';

// Used for news preview (e.g. title, meta, link)
const NewsPreview = (props) => (
  <Reveal effect="fade-in-up" duration={1000}>
    <Row className="NewsPreview" around="xs" start="xs">
      <Col lg={9} xs={12}>
        <NewsMeta categories={props.categories} date={props.customDate !== '' ? props.customDate : props._created} />
        <Link to={`/news/${props.slug}`} title={`Read: ${props.title}`} className="no-styling">
          <h2>{props.title}</h2>
        </Link>
        <PageLink {...{
          icon: 'arrow', text: 'Read', title: `Read: ${props.title}`, url: `/news/${props.slug}`,
        }}
        />
      </Col>
    </Row>
  </Reveal>
);

NewsPreview.propTypes = {
  _created: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  customDate: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewsPreview;
