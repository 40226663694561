import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { withRouter } from 'react-router-dom';

import Footer from './Footer';
import NewsTeaser from './NewsTeaser';
import PageContent from './PageContent';
import PageContact from './PageContact';
import PageDevices from './PageDevices';
import PageExplosion from './PageExplosion';
import PageHeader from './PageHeader';
import PageLightUI from './PageLightUI';
import PageSlider from './PageSlider';

// Used to translate settings from page data into desired layout
class Page extends Component {
  constructor(props) {
    super(props);

    this.firstSection = React.createRef();

    this.handleScrollTo = this.handleScrollTo.bind(this);
    this.renderComponents = this.renderComponents.bind(this);
  }

  // Handle scroll to first section of site
  handleScrollTo() {
    window.scrollTo({
      top: this.firstSection.current.offsetTop,
      behavior: 'smooth',
    });
  }

  renderComponents() {
    let contents = [];

    contents = this.props.data.map((component, index) => {
      let content;

      if (index >= 1) {
        const props = {
          key: index,
          ...component,
        };

        // Give second section of page firstSection prop (for handleScrollTo()-function)
        if (index === 2) {
          props.refProp = this.firstSection;
        }

        // Get component based on type
        switch (component.type) {
          case 'contact':
            content = <PageContact {...component} {...props} contact={this.props.layout.contact} />;
            break;

          case 'content':
            content = <PageContent {...component} {...props} />;
            break;

          case 'devices':
            content = <PageDevices {...component} {...props} />;
            break;

          case 'explosion':
            content = <PageExplosion {...component} {...props} />;
            break;

          case 'header':
            content = <PageHeader {...component} {...props} handleScrollTo={this.handleScrollTo} />;
            break;

          case 'lightUI':
            content = <PageLightUI {...component} {...props} />;
            break;

          case 'newsTeaser':
            content = (
              <NewsTeaser
                {...component}
                {...props}
                latestArticles={this.props.layout.latestArticles.entries}
              />
            );
            break;

          case 'slider':
            content = <PageSlider {...component} {...props} />;
            break;

          default:
            break;
        }
      }

      return content;
    }, this);

    return contents;
  }

  render() {
    return (
      <DocumentMeta {...this.props.data[0].meta}>
        <div id="main" className={`Page ${this.props.data[0].class} theme-${this.props.data[0].theme}`}>
          {this.renderComponents()}
        </div>
        <Footer data={this.props.layout.footer} />
      </DocumentMeta>
    );
  }
}

export default withRouter(Page);
